<template>
  <div class="pic-iframe"
       v-if="!$layout.isHtmlCaching"
       v-intersect="defaultIntersect">
    <v-responsive :aspect-ratio="16/9" class="video-ratio">
      <div class="bg-noise"
           :style="`background-image: url('${$layout.publicPath}noise.gif');`"/>

      <div v-if="true || isIntersecting && !$layout.isHtmlCaching" >

        <!-- debug
        <div style="color:red;position:absolute;z-index: 9999999999999999999999999">
          {{isTurning?"turn":"not turn"}} |{{playing?"playing":"not playing"}} | {{$glob.iframe===randomId?"playing":"not playing"}} |  {{$glob.iframe}} | {{playcount}} | {{randomId}}
        </div>
        -->-->
        <template v-if="image">
          <!-- iframe qui remplace l'image de preview -->
          <iframe
              v-if="!placeholderImage"
              class="" style="opacity: 0.7;pointer-events: none"
              width="100%" height="100%"
              :src="iframeBlock.iframeSrc"
              frameborder="0"
          />
          <div @click="play"
               class="image"
               :style="{
                    'background-image':`url(${image})`,

                  }">
            <v-btn fab color="var(--color-project)">
              <v-icon size="60">{{$icons.playCircle}}</v-icon>
            </v-btn>

          </div>
        </template>

        <template v-else>
          <div v-if="iframeBlock.provider==='iframe'"
               v-html="iframeBlock.userInput"/>
          <iframe
              v-else-if="iframeBlock.iframeSrc"
              class="animate__animated animate__fadeIn animate__slow"
              width="100%" height="100%"
              :src="iframeBlock.iframeSrcWithOptions"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
              allowfullscreen
          />
        </template>
      </div>
    </v-responsive>
  </div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import InVpMixin from "@/components/atoms/InVpMixin";

export default {
  name: "pic-iframe",
  components: {},
  mixins:[BlockMixin,InVpMixin],
  data(){
    return{
      isTurning:false,
      randomId:Math.random().toString().slice(2, 12),
      playcount:0
    }
  },
  mounted(){
    screen.orientation.addEventListener("change", this.onOrientationChange);
  },
  beforeDestroy(){
    screen.orientation.removeEventListener("change", this.onOrientationChange);
  },
  computed:{
    /**
     *
     * @return {IframeBlock}
     */
    iframeBlock(){
      return this.block;
    },
    image(){
      if(this.playing){
        return false;
      }
      if(this.placeholderImage){
        return this.placeholderImage;
      }else{
        return "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
      }
    },
    placeholderImage(){
      if(this.iframeBlock.image.isOk){
        return this.iframeBlock.image.href;
      }
      return null;
    },
    playing(){
      return this.$glob.iframe === this.randomId.toString();
    }

  },
  methods:{
    onOrientationChange(event){
      const type = event.target.type;
      const angle = event.target.angle;
      console.log(`ScreenOrientation change: ${type}, ${angle} degrees.`);
      this.isTurning=true;
      setTimeout(()=>{
        this.isTurning=false;
        if(this.playing){
            this.$el.scrollIntoView({behavior:"smooth",block:"center",inline:"center"});
        }
      },2000);
    },
    play(){
      this.playcount++;
      //this.playing=true;
      this.$glob.iframe=this.randomId;
    },
  },
  watch:{
    isIntersecting(){
      if(this.isTurning || this.playing){
        return;
      }
      if(!this.isIntersecting && this.playing){
        this.$glob.iframe="intersec off";
      }
    }
  }
}
</script>

<style lang="less">

.pic-iframe{
  .video-ratio{
    background-color: #111;
    .bg-noise{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      display: block;
      background-repeat: repeat;
      filter: contrast(0.5) brightness(0.2);
    }
  }
  .image{
    cursor: pointer;
    opacity: 0.8;
    //pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  iframe{
    margin: 0;
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    border: 1px solid #111;
  }
}


</style>