<template>
  <div class="accordion-location-block has-href-underline"
       :data-open="open"
       :id="blc.uid">
    <v-container class="py-0">
      <v-row class="toggler my-0"
             :class="{'openable':openAble}"
             align="center"
             @click="openClose"
      >
        <v-col :cols="2" class="img pa-0">
            <img class="preview-img"
                 :alt="blc.title"
                 :src="blc.photos.getFirst()
                 ?blc.photos.getFirst().image.resize(300,300,'cover','000000',90,'webp')
                 :'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='"/>
        </v-col>
        <v-col class="ty-title-link like-a u-span text">
          {{blc.title}}
        </v-col>
        <v-col cols="2" md="3" class="text-right icon" v-if="openAble">
          <v-icon size="40" color="var(--color-project)">{{icon}}</v-icon>
        </v-col>
      </v-row>
      <v-row class="content my-0">
          <v-col cols="12" class="py-5" v-if="!bottomEmpty">
              <v-row>
                  <v-col cols="12"
                         md="6"
                         class="px-lg-16 py-0 py-md-5">
                      <div v-html="blc.txt2" class="ty-rich-text"/>
                  </v-col>
                  <v-col cols="12"
                         md="6"
                         class="px-lg-16 py-0 py-md-5">
                      <div v-html="blc.txt3" class="ty-rich-text"/>
                  </v-col>
              </v-row>
          </v-col>
      </v-row>

    </v-container>
  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "accordion-location-block",
  components: {},
  mixins:[BlockMixin],
  data(){
    return{
      open:false
    }
  },
    methods:{
      openClose(){
        if(this.openAble){
            this.open=!this.open;
        }
      },
      isHtmlEmpty(htmlString){
          const aux = document.createElement('div');
          aux.innerHTML = htmlString; //parses the html
          const trimmedContent = aux.innerText.trim();
          return trimmedContent === ""
      }
    },
  computed:{
      openAble(){
          if(!this.bottomEmpty){
              return true;
          }
          if(!this.isHtmlEmpty(this.blc.txt1)){
              return true;
          }
          return false;
      },
    topEmpty(){
        return !this.blc.photos.items.length && this.isHtmlEmpty(this.blc.txt1);
    },
    bottomEmpty(){
        return this.isHtmlEmpty(this.blc.txt2) && this.isHtmlEmpty(this.blc.txt3);
    },
    icon(){
      return this.open?this.$icons.close:this.$icons.plus;
    },
    /**
     *
     * @return {AccordionLocationBlock}
     */
    blc(){
      return this.block;
    }
  },
  watch:{ }
}
</script>

<style lang="less">
.accordion-location-block{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .preview-img{
    background-color: #000;
    display: block;
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  .toggler{
    user-select: none;
    &.openable{
      cursor: pointer;
    }
    --href-underline-top: 100%;
  }
  .content{
    transition: all 0.5s;
    transition-timing-function: ease-out;
    //border: 0 solid transparent;
    border: 1px solid #fff;
    border-top-width: 0px;
    max-height: 0;
    overflow-y: hidden;
    .media{
      .video-ratio{
        height: 100%;
      }
      height: calc( 100vw * 1 );
      @media(min-width: @screen-md){
        height: calc( (840px/2) * 1 );
      }
      @media(min-width: @screen-lg){
        height: calc( (1180px/2) * 1 );
      }
      @media(min-width: @screen-xl){
        height: calc( (1440px/2) * 1 );
      }
    }
  }
  .toggler{
    >.img{
      transition: all 0.5s;
    }
    >.text{
      @media(min-width: @screen-md){
        padding-left: 50px !important;
        transition: all 0.5s;
      }
      @media(max-width: 500px){
        font-size: 16px !important;
      }
    }
  }
  &[data-open]{
    .toggler{
      /*
      >.img{
        overflow: hidden;
        width: 0!important;
        height: 0!important;
        padding: 0!important;
        max-width: 0;
      }

       */
      >.text{
        @media(min-width: @screen-md){
          //padding-left: 0px !important;
        }
      }

    }
  }
  &[data-open]{
    .content{
      transition-timing-function: ease-in;
      border-top-width: 1px;
      max-height: 3000px;
      overflow-y: auto;
      margin-bottom: 30px !important;
    }
  }



  --sb-track-color: #000000;
  --sb-thumb-color: rgba(141, 141, 141, 0.5);
  --sb-size: 8px;

  scrollbar-color: var(--sb-thumb-color)
  var(--sb-track-color);


  ::-webkit-scrollbar {
    width: var(--sb-size);
    border: 1px solid var(--sb-track-color);
  }

  ::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
    border: 1px solid var(--sb-track-color);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;
    border: 3px solid var(--sb-track-color);
  }


}
</style>